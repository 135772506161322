
import { Env } from "./Env";
import { Firebase } from './Firebase'
import { Server } from './Server'
import { Session } from './Session'

export class State {
    public env = new Env();
    public firebase = new Firebase()
    public server = new Server()
    public session = new Session()
}

export const state = new State();
