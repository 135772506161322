import React from 'react';
import { observer } from 'mobx-react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import cx from 'classnames'

import SessionError from './SessionError'
import { state } from '../../models/State'
import {
    handleFormChange,
    handleSubmit,
    handleSwitchSignIn
} from '../../actions/Session'

const SignUp = () => (
    <Form onSubmit={handleSubmit} onChange={handleFormChange}>
        <SessionError />
        <InputGroup className="mb-3">
            <InputGroup.Prepend>
                <InputGroup.Text id="signin_email">Email</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
                placeholder="user@email.com"
                aria-label="Email"
                aria-describedby="signin_email"
                name="email"
                autoComplete="current-email"
            />
        </InputGroup>
        <InputGroup className="mb-3">
            <InputGroup.Prepend>
                <InputGroup.Text id="singin_pass">Password</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
                placeholder="**********"
                aria-label="Password"
                aria-describedby="singin_pass"
                name="pass"
                type="password"
                autoComplete="current-password"
            />
        </InputGroup>
        <ButtonToolbar>
            <Button variant="link" onClick={handleSwitchSignIn}>Create an account</Button>
            <input className={cx("btn btn-primary", { disabled: state.session.loading })} type="submit" value="Login" disabled={state.session.loading} />
        </ButtonToolbar>
    </Form >
)

export default observer(SignUp);
