import React from 'react'
import { observer } from 'mobx-react'
import Alert from 'react-bootstrap/Alert'


import { state } from '../../models/State'
import { resetSessionError } from '../../actions/Session';

const SessionError = () => {
    if (!state.session.errorMessage) {
        return null
    }
    return (
        <Alert
            variant="danger"
            dismissible
            title={state.session.errorMessage.code}
            onClose={resetSessionError}
        >
            {state.session.errorMessage.message}
        </Alert>
    )
}

export default observer(SessionError);
