import React, { Component } from 'react';
import { observer } from 'mobx-react';

import APIStatus from './views/APIStatus';
import FirebaseUserProfile from './views/session/FirebaseUserProfile'
import ConnectionModal from './views/session/ConnectionModal'
import './App.scss';
import { state } from './models/State';

@observer
class App extends Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">
          {state.env.isProd() ? null : <APIStatus />}
          <ConnectionModal />
          <FirebaseUserProfile />
        </header>
      </div>
    );
  }
}

export default App;
