import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import './App';
import * as serviceWorker from './serviceWorker';
import { state } from './models/State';

const hook = () => {
    const App = require('./App').default;
    ReactDOM.render(<App />, document.getElementById('root'));
}

hook();

if (module.hot) {
    module.hot.accept('./App', hook);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

state.server.getServerStatus();
setInterval(() => {
    state.server.getServerStatus();
}, 100000);

(window as any).state = state;
