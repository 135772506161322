import React, { Fragment } from "react";
import { observer } from 'mobx-react'
import cx from 'classnames'

import { state } from '../models/State'
import styles from './APIStatus.module.scss';

const APIStatus = () => (
    <div className={cx(styles.container, styles[state.server.serverStatus], { [styles.pending]: state.server.refreshing })}>
        Server {state.server.serverStatus} <i>{state.server.pingTime}ms</i>
    </div>
);

export default observer(APIStatus);
