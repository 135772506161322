import React from "react";
import { observer } from 'mobx-react';
import ReactJson from 'react-json-view'

import { state } from '../../models/State'
import styles from './FirebaseUserProfile.module.scss'

export const FirebaseUserProfile = () => {
    return (
        <div className={styles.container}>
            <ReactJson
                src={state.session.user || {}}
                theme="summerfruit"
                enableClipboard={false}
                // onEdit={(obj) => console.error(obj)}
                style={{
                    padding: '1em',
                    borderRadius: '.5em',
                    margin: '1em',
                    overflow: 'auto'
                }}
            />
        </div>
    )
}

export default observer(FirebaseUserProfile);
