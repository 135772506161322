import { observable } from 'mobx'

import { ServerStatus } from '../types/ServerStatus'
import { state } from './State';

export class Server {
    @observable public serverStatus: ServerStatus = ServerStatus.UNKNOWN;
    @observable public pingTime = 0
    @observable public refreshing = true;

    private async fetch(url: string, options?: any) {
        const time = Date.now()
        this.refreshing = true
        try {
            const response = await fetch(url, options);
            const data = await response.json()
            this.refreshing = false;
            this.serverStatus = ServerStatus.ONLINE;
            this.pingTime = Date.now() - time;
            return data
        } catch (err) {
            console.error(err);
            this.serverStatus = ServerStatus.OFFLINE;
        }
        this.refreshing = false;
    }

    async getServerStatus() {
        await this.fetch(state.env.apiUrl);
    }
}