import React, { MouseEvent } from 'react';
import { observer } from 'mobx-react';
import {
    Button,
} from 'react-bootstrap';

import { state } from '../../models/State'

const handleSignOut = (ev: MouseEvent<HTMLButtonElement>) => {
    state.session.loading = true;
    ev.preventDefault();
    state.firebase.signOut();
}

const SignOut = () => (
    <Button onClick={handleSignOut} disabled={state.session.loading}>SignOut</Button>
)

export default observer(SignOut);
