import React from 'react';
import { observer } from 'mobx-react';
import Modal from 'react-bootstrap/Modal';

import { state } from '../../models/State'
import SignIn from './SignIn'
import SignUp from './SignUp'
import SignOut from './SignOut'

const ConnectionModal = () => {
    if (state.session.user === null) {
        return (
            <Modal show={!state.session.loading} backdrop="static" >
                <Modal.Header>
                    <Modal.Title>{state.session.needSignUp ? 'Sign up' : 'Sign in'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {state.session.needSignUp ? <SignUp /> : <SignIn />}
                </Modal.Body>
            </Modal>
        )
    } else {
        return (
            <SignOut />
        );
    }
}

export default observer(ConnectionModal);
