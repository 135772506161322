import 'firebase/auth'
import { User, initializeApp } from 'firebase/app'
import { FormEvent, MouseEvent } from 'react';

import { state } from '../models/State'
import { firebaseConfig } from '../config/Firebase';
import { IFirebaseUser, IAuthError } from '../types/Firebase'

export const resetSessionError = () => {
    state.session.errorMessage = null;
}

export const setup = () => {
    const instance = initializeApp(firebaseConfig);
    instance.auth().onAuthStateChanged((user: User | null) => {
        const u = user === null ? null : user.toJSON() as IFirebaseUser;
        state.session.user = u;
        state.session.loading = false;
    });

    return instance;
}

export const handleFormChange = (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    const target = ev.target as HTMLInputElement
    const field = target.name as 'email' | 'pass' | 'pass2'
    state.session.form[field] = target.value;
}

export const handleSubmit = (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    resetSessionError();
    state.session.loading = true;

    const handleError = (err: IAuthError) => {
        state.session.loading = false;
        state.session.errorMessage = err;
    }

    if (state.session.needSignUp) {
        if (state.session.form.pass !== state.session.form.pass2) {
            handleError({
                code: 'confirm/pass',
                message: 'Password does not match'
            })
            return
        }
        state.firebase
            .signUp(state.session.form.email, state.session.form.pass)
            .then(resetSessionError)
            .catch(handleError);
    } else {
        state.firebase
            .signIn(state.session.form.email, state.session.form.pass)
            .then(resetSessionError)
            .catch(handleError);
    }
}

export const handleSwitchSignIn = (ev: MouseEvent<HTMLButtonElement>) => {
    ev.preventDefault();
    resetSessionError();
    state.session.loading = false;
    state.session.needSignUp = !state.session.needSignUp;
}