import { setup } from '../actions/Session'

export class Firebase {
    public instance = setup();

    signIn(email: string, password: string) {
        return this.instance.auth().signInWithEmailAndPassword(email, password)
    }

    signUp(email: string, password: string) {
        return this.instance.auth().createUserWithEmailAndPassword(email, password);
    }

    signOut() {
        return this.instance.auth().signOut();
    }
}