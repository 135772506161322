import { observable } from 'mobx'

import { IAuthError } from '../types/Firebase';

export class Session {
    @observable public loading = true;
    @observable public needSignUp = false;
    @observable public user: any = null;
    @observable public errorMessage: IAuthError | null = null;
    @observable public form = {
        email: '',
        pass: '',
        pass2: ''
    };
}